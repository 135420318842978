var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', {
    staticClass: "match-height"
  }, [_c('b-col', {
    attrs: {
      "md": "6",
      "lg": "4"
    }
  }, [_c('b-card', {
    attrs: {
      "img-src": require('@/assets/images/slider/04.jpg'),
      "img-alt": "Card image cap",
      "img-top": "",
      "title": "Card title"
    }
  }, [_c('b-card-text', [_vm._v(" Some quick example text to build on the card title and make up the bulk of the card's content. ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "outline-primary"
    }
  }, [_vm._v(" Go Somewhere ")])], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "4"
    }
  }, [_c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-body', [_c('b-card-title', [_vm._v("Card title")]), _c('b-card-sub-title', [_vm._v("Support card subtitle")])], 1), _c('b-img', {
    attrs: {
      "fluid": "",
      "src": require('@/assets/images/slider/03.jpg'),
      "alt": "Card image cap"
    }
  }), _c('b-card-body', [_c('b-card-text', [_vm._v("Bear claw sesame snaps gummies chocolate.")]), _c('b-link', {
    staticClass: "card-link"
  }, [_vm._v(" Card link ")]), _c('b-link', {
    staticClass: "card-link"
  }, [_vm._v(" Another link ")])], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "4"
    }
  }, [_c('b-card', {
    attrs: {
      "title": "Card title",
      "sub-title": "Support card subtitle"
    }
  }, [_c('b-img', {
    staticClass: "mb-2",
    attrs: {
      "fluid": "",
      "src": require('@/assets/images/slider/06.jpg')
    }
  }), _c('b-card-text', [_vm._v("Bear claw sesame snaps gummies chocolate.")]), _c('b-link', {
    staticClass: "card-link"
  }, [_vm._v(" Card link ")]), _c('b-link', {
    staticClass: "card-link"
  }, [_vm._v(" Another link ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h6', {
    staticClass: "my-2 text-muted"
  }, [_vm._v(" Header and Footer ")]), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6",
      "lg": "4"
    }
  }, [_c('b-card', {
    attrs: {
      "header": "Featured",
      "title": "Special title treatment"
    }
  }, [_c('b-card-text', [_vm._v(" With supporting text below as a natural lead-in to additional content natural lead-in to additional content. ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "outline-primary"
    }
  }, [_vm._v(" Go somewhere ")])], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "4"
    }
  }, [_c('b-card', {
    attrs: {
      "header": "Quote",
      "header-tag": "h6"
    }
  }, [_c('blockquote', {
    staticClass: "blockquote mb-0"
  }, [_c('p', [_vm._v(" Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.Lorem ipsum dolor sit amet, Integer posuere erat a ante Integer posuere erat a anteconsectetur. ")]), _c('footer', {
    staticClass: "blockquote-footer"
  }, [_vm._v(" Someone famous in "), _c('cite', {
    attrs: {
      "title": "Source Title"
    }
  }, [_vm._v("Source Title")])])])])], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "4"
    }
  }, [_c('b-card', {
    staticClass: "text-center",
    attrs: {
      "header": "Featured",
      "title": "Special title treatment",
      "footer": "2 days ago",
      "footer-class": "text-muted"
    }
  }, [_c('b-card-text', [_vm._v(" With supporting text below as a natural. ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "outline-primary"
    }
  }, [_vm._v(" Go somewhere ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('h6', {
    staticClass: "my-3 text-muted"
  }, [_vm._v(" Card Groups ")])]), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-card-group', {
    staticClass: "mb-0"
  }, [_c('b-card', {
    attrs: {
      "img-src": require('@/assets/images/slider/01.jpg'),
      "img-alt": "Card image cap",
      "img-top": "",
      "no-body": ""
    }
  }, [_c('b-card-body', [_c('b-card-title', [_vm._v("Card title")]), _c('b-card-text', [_vm._v(" This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer. ")])], 1), _c('b-card-footer', [_c('small', {
    staticClass: "text-muted"
  }, [_vm._v("Last updated 3 mins ago")])])], 1), _c('b-card', {
    attrs: {
      "img-src": require('@/assets/images/slider/05.jpg'),
      "img-alt": "Card image cap",
      "img-top": "",
      "no-body": ""
    }
  }, [_c('b-card-body', [_c('b-card-title', [_vm._v("Card title")]), _c('b-card-text', [_vm._v(" This card has supporting text below as a natural lead-in to additional content. ")])], 1), _c('b-card-footer', [_c('small', {
    staticClass: "text-muted"
  }, [_vm._v("Last updated 3 mins ago")])])], 1), _c('b-card', {
    attrs: {
      "img-src": require('@/assets/images/slider/03.jpg'),
      "img-alt": "Card image cap",
      "img-top": "",
      "no-body": ""
    }
  }, [_c('b-card-body', [_c('b-card-title', [_vm._v("Card title")]), _c('b-card-text', [_vm._v(" This is a wider card with supporting text below as a natural lead-in to additional content. This card has even longer content than the first to show that equal height action. ")])], 1), _c('b-card-footer', [_c('small', {
    staticClass: "text-muted"
  }, [_vm._v("Last updated 3 mins ago")])])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
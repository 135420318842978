var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    attrs: {
      "id": "card-images"
    }
  }, [_c('h5', {
    staticClass: "mt-3 mb-2"
  }, [_vm._v(" Images ")]), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6",
      "xl": "4"
    }
  }, [_c('b-card', {
    staticClass: "mb-3",
    attrs: {
      "img-src": require('@/assets/images/slider/06.jpg'),
      "img-top": "",
      "img-alt": "card img",
      "title": "Card title"
    }
  }, [_c('b-card-text', [_vm._v(" This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer. ")]), _c('b-card-text', [_c('small', {
    staticClass: "text-muted"
  }, [_vm._v("Last updated 3 mins ago")])])], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "xl": "4"
    }
  }, [_c('b-card', {
    staticClass: "mb-3",
    attrs: {
      "img-src": require('@/assets/images/slider/09.jpg'),
      "img-bottom": "",
      "img-alt": "card img",
      "title": "Card title"
    }
  }, [_c('b-card-text', [_vm._v(" This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer. ")]), _c('b-card-text', [_c('small', {
    staticClass: "text-muted"
  }, [_vm._v("Last updated 3 mins ago")])])], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "xl": "4"
    }
  }, [_c('b-card', {
    attrs: {
      "img-src": require('@/assets/images/slider/10.jpg'),
      "overlay": "",
      "text-variant": "white",
      "img-alt": "card img",
      "body-class": "bg-overlay"
    }
  }, [_c('b-card-title', {
    staticClass: "text-white"
  }, [_vm._v(" Card title ")]), _c('b-card-text', [_vm._v(" This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer. ")]), _c('b-card-text', [_c('small', {
    staticClass: "text-muted"
  }, [_vm._v("Last updated 3 mins ago")])])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }